import {
  FormControl,
  Grid,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import { DraftsOutlined, EditOutlined, InboxOutlined, SendOutlined, VisibilityOutlined } from '@material-ui/icons';
import { Pagination } from '@material-ui/lab';
import { makeStyles, withStyles } from '@material-ui/styles';
import OrderConfirmedForm from 'component/Application/Order-Confirmation/OrderConfirmedForm';
import PageContainer from 'containers/PageComponents/layouts/PageContainer';
import { PopupSection } from 'customControls';
import { SettingsIcon } from 'customControls/ActionButtons/ActionButtons';
import Moment from 'moment';
import qs from 'querystring';
import React, { useEffect, useState } from 'react';
import axios, { urls } from 'services/auth/jwt/config';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
    // backgroundColor: theme.palette.background.paper,
  },
  mainDiv: {
    backgroundColor: 'white',
    justifyContent: 'center',
    padding: '25px'
  },
  table: {
    '& thead th': {
      fontWeight: 'bold',
      color: 'black',
      backgroundColor: '#C6C6C6'
    },
    '& tbody td': {
      fontWeight: 'normal'
    },
    '& tbody tr:hover': {
      backgroundColor: '#e8f4f8',
      cursor: 'pointer'
    }
  },
  formControl: {
    marginRight: theme.spacing(2),
    minWidth: 120,
    fontWeight: 50
  },
  tab: {
    color: 'green',
    fontWeight: 'bold',
    margin: '5px',
    backgroundColor: 'white',
    border: 'solid 2px #C6C6C6'
  },
  tContainer: {
    marginBottom: theme.spacing(3)
  }
}));
//For Menu
const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5'
  }
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center'
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(theme => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white
      }
    }
  }
}))(MenuItem);

///For Status
const ConfirmationStatus = {
  Reject: 'Reject',
  Review: 'Review',
  Approve: 'Approve'
};
const ConfirmOrderListPage = props => {
  const { previewOrder, history } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  //*** Start for Pending List
  const [orderPending, setOrderPending] = useState([]);

  const pendigPages = [10, 25, 30];
  const [pendingPage, setPendingPage] = useState(0);
  const [pendingRowsPerPage, setPendingRowsPerPage] = useState(pendigPages[pendingPage]);
  const [pendingDataLength, setPendingDataLength] = useState(0);
  const [pendingPageNumber, setPendingPageNumber] = useState(1);
  const [pendingSortedBy, setPendingSortedBy] = useState('DESC');
  const [pendingSortedColumn, setPendingSortedColumn] = useState('ID');

  const [openPopup, setOpenPopup] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(null);
  //End ****//

  const getAllPendingOrders = async () => {
    const filterBody = {
      PageNumber: pendingPageNumber,
      PageSize: pendingRowsPerPage,
      SortedColumn: pendingSortedColumn,
      SortedBy: pendingSortedBy
    };
    //console.log(filterBody);
    try {
      await axios.get(`${urls.order.get_pending_orders}?${qs.stringify(filterBody)}`).then(({ data }) => {
        //console.log(data);
        if (data.succeeded) {
          const body = data.data;
          setOrderPending(body);
          setPendingDataLength(data.totalCount);
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  ///

  const handleOpenApproveForm = (orderId, status) => {
    const body = {
      orderId: orderId,
      status: status
    };
    setRecordForEdit(body);
    setOpenPopup(true);
  };

  const closePopup = () => {
    setOpenPopup(false);
    getAllPendingOrders();
  };
  ///

  useEffect(() => {
    getAllPendingOrders();
  }, [pendingRowsPerPage, pendingPageNumber]);

  const handleActionButtonOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleActionButtonClose = () => {
    setAnchorEl(null);
  };

  const handleChangePage = (event, pageNumber) => {
    setPendingPage(pageNumber);
    setPendingPageNumber(pageNumber);
  };

  const handleChangeRowsPerPage = event => {
    setPendingRowsPerPage(parseInt(event.target.value));
    setPendingPage(0);
  };

  // const handleSortRequest = cellId => {
  //   const isAsc = pendingSortedColumn === cellId && pendingSortedBy === 'asc';
  //   setPendingSortedBy(isAsc ? 'desc' : 'asc');
  //   setPendingSortedColumn(cellId);
  // };

  const handleEdit = orderId => {
    const reviewOrder = true;
    history('/orders/edit', { orderId, reviewOrder });
  };

  return (
    <PageContainer>
      <div>
        <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
          <TableContainer className={classes.tContainer} component={Paper} padding="default">
            <Table className={classes.table} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>SL</TableCell>
                  <TableCell>Order No.</TableCell>
                  <TableCell align="left">Requested No.</TableCell>
                  <TableCell align="left">Request Date</TableCell>
                  <TableCell align="left">Confirmed Date</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              {orderPending.length > 0 ? (
                <TableBody>
                  {orderPending.map((item, index) => (
                    <TableRow key={item.id}>
                      <TableCell component="th" scope="row">
                        {index + 1}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {item.orderNumber === '' ? 'Requested' : item.orderNumber}
                      </TableCell>
                      <TableCell align="left">{item.reqOrderNumber}</TableCell>
                      <TableCell>{Moment(item.requestDateAndTime).format('DD-MMM-yyyy')}</TableCell>
                      <TableCell>
                        {item.acceptanceDateAndTime === null
                          ? 'NA'
                          : Moment(item.acceptanceDateAndTime).format('DD-MMM-yyyy')}
                      </TableCell>
                      <TableCell align="center">
                        <SettingsIcon onClick={handleActionButtonOpen} title="Action" placement="left" />
                        <StyledMenu
                          id="customized-menu"
                          anchorEl={anchorEl}
                          keepMounted
                          open={Boolean(anchorEl)}
                          onClose={handleActionButtonClose}>
                          <StyledMenuItem
                            onClick={() => {
                              setOpenPopup(true);
                              handleOpenApproveForm(item.id, ConfirmationStatus.Approve);
                            }}>
                            <ListItemIcon>
                              <SendOutlined fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="Approved" />
                          </StyledMenuItem>
                          <StyledMenuItem
                            onClick={() => {
                              setOpenPopup(true);
                              handleOpenApproveForm(item.id, ConfirmationStatus.Reject);
                            }}>
                            <ListItemIcon>
                              <DraftsOutlined fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="Reject" />
                          </StyledMenuItem>
                          <StyledMenuItem
                            onClick={() => {
                              setOpenPopup(true);
                              handleOpenApproveForm(item.id, ConfirmationStatus.Review);
                            }}>
                            <ListItemIcon>
                              <InboxOutlined fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="Review" />
                          </StyledMenuItem>
                          <StyledMenuItem
                            onClick={() => {
                              previewOrder(item.id);
                            }}>
                            <ListItemIcon>
                              <VisibilityOutlined color="primary" fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="View" />
                          </StyledMenuItem>
                          {item.isCreator && (
                            <StyledMenuItem
                              onClick={() => {
                                handleEdit(item.id);
                              }}>
                              <ListItemIcon>
                                <EditOutlined color="primary" fontSize="small" />
                              </ListItemIcon>
                              <ListItemText primary="Edit" />
                            </StyledMenuItem>
                          )}
                        </StyledMenu>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              ) : null}
            </Table>
          </TableContainer>

          <Grid container item xs={12} sm={12} md={12} lg={4} justifyContent="flex-start">
            <FormControl className={classes.formControl}>
              <Typography> Row Per Page : </Typography>
            </FormControl>
            <FormControl className={classes.formControl}>
              <Select id="select-label-row" value={pendingRowsPerPage} onChange={handleChangeRowsPerPage}>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={15}>15</MenuItem>
                <MenuItem value={20}>20</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid container item xs={12} sm={12} md={12} lg={8} justifyContent="flex-end">
            <Pagination
              count={Math.ceil(pendingDataLength / pendingRowsPerPage)}
              variant="outlined"
              color="primary"
              onChange={handleChangePage}
              showFirstButton
              showLastButton
            />
          </Grid>
        </Grid>

        <PopupSection title="Remarking...." openPopup={openPopup} setOpenPopup={setOpenPopup}>
          <OrderConfirmedForm closePopup={closePopup} recordForEdit={recordForEdit} />
        </PopupSection>
      </div>
    </PageContainer>
  );
};

export default ConfirmOrderListPage;
