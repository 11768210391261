import { Box, Button, Dialog, DialogContent } from '@material-ui/core';
import React from 'react';
import logo from '../../../assets/erlLoginImage.png';
import './splash.css';
const SplashModal = ({ open, setOpen, fullWidth, title, fullScreen, children, ...rest }) => {
  const handleClose = (event, reason) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return false;
    } else {
      setOpen(false);
    }
  };
  return (
    <Box>
      <Dialog maxWidth="xl" fullWidth={fullWidth} onClose={handleClose} open={open} fullScreen={fullScreen}>
        <DialogContent style={{ padding: '0px' }}>
          <div className="splash">
            <div className="splash_logo">
              <img
                src={logo}
                alt="No Image"
                style={{ border: '5px solid white', marginTop: '80px', height: '70px', width: '100px' }}
              />
            </div>
            <div className="splash_svg">
              <svg style={{ height: '100%', width: '100%' }}>
                {' '}
                <rect style={{ height: '100%', width: '100%' }} />
              </svg>
            </div>
            <div className="splash_minimize">
              <svg style={{ height: '100%', width: '100%' }}>
                {' '}
                <rect style={{ height: '100%', width: '100%' }} />
              </svg>
            </div>
          </div>
          <div className="text">
            <p style={{ fontSize: '18px' }}>Copyright © Eastern Refinery Limited </p>

            <p style={{ fontSize: '16px' }}>Designed by </p>
            <p style={{ fontSize: '14px' }}> Engr. Jamil Al Mamun & Engr Mostafizar Rahman</p>
            <p style={{ fontSize: '14px' }}>
              Developed by <span>NVIT Ltd.</span>
            </p>

            {/* <button>More</button> */}
            <Button
              style={{ marginTop: '1rem' }}
              id="btn"
              autoFocus
              variant="contained"
              onClick={handleClose}
              color="primary">
              Get Started
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default SplashModal;
