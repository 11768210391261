import { Button, Grid, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import axios, { urls } from 'services/auth/jwt/config';
import { alerts } from 'utils/alert';

const useStyles = makeStyles(theme => ({
  textField: {
    margin: theme.spacing(6),
    width: '90%'
  }
}));

export default function OrderConfirmedForm(props) {
  const classes = useStyles();
  const { recordForEdit, closePopup } = props;
  const [orderConfirm, setOrderConfirm] = useState({
    // eslint-disable-next-line no-use-before-define
    ...orderConfirm,
    orderId: recordForEdit.orderId,
    status: recordForEdit.status,
    remarks: ''
  });
  const handleSubmit = () => {
    axios
      .post(`${urls.order.put_order_confirmation}/${orderConfirm.orderId}`, orderConfirm)
      .then(({ data }) => {
        if (data.succeeded) {
          alerts('success', `${data.message}`);
          closePopup();
        }
      })
      .catch(error => {
        console.log(error);
      });
  };
  return (
    <Grid container justifyContent="center" spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <TextField
          className={classes.textField}
          variant="outlined"
          margin="normal"
          label={`Please write a remark for ${recordForEdit.status}`}
          value={recordForEdit.remarks}
          name="remarks"
          onChange={e => {
            setOrderConfirm({ ...orderConfirm, remarks: e.target.value });
          }}
        />
      </Grid>
      <Grid container item xs={12} sm={12} md={12} lg={12} justifyContent="flex-end">
        <Button
          color="primary"
          variant="outlined"
          onClick={() => {
            handleSubmit();
          }}>
          Submit
        </Button>
      </Grid>
    </Grid>
  );
}
