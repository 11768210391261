import { alpha, Box, makeStyles, Tooltip } from '@material-ui/core';
import { PersonPin, PowerSettingsNewOutlined } from '@material-ui/icons';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PersonIcon from '@material-ui/icons/Person';
import clsx from 'clsx';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AuhMethods } from 'services/auth';
import { CurrentAuthMethod } from '../../../constants/AppConstants';
const imageDataType = 'data:image/png;base64';

const useStyles = makeStyles(theme => ({
  profileRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 10,
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      paddingLeft: 20
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 2,
      zIndex: 1,
      // height: 35,
      width: 1,
      backgroundColor: alpha(theme.palette.common.dark, 0.15)
    }
  },
  userName: {
    paddingLeft: 10
  }
}));

const UserDropDown = () => {
  const classes = useStyles();
  const { authUser } = useSelector(({ auth }) => auth);
  const dispatch = useDispatch();
  const actionsList = [
    {
      icon: <PersonPin />,
      label: authUser?.firstName.concat(authUser?.lastName)
    },
    {
      icon: <PersonIcon />,
      label: 'Profile'
    },
    {
      icon: <ExitToAppIcon />,
      label: 'Logout'
    }
  ];
  const onItemClick = () => {
    dispatch(AuhMethods[CurrentAuthMethod].onLogout());
  };
  // const onItemClick = item => {
  //   if (item.label === 'Logout') {
  //     dispatch(AuhMethods[CurrentAuthMethod].onLogout());
  //   }
  //   if (item.label === 'Profile') {
  //     window.location.href = '/accounts/profile';
  //   }
  // };

  return (
    <Box className={clsx(classes.profileRoot)}>
      {/* <CmtDropdownMenu
        onItemClick={onItemClick}
        TriggerComponent={<CmtAvatar className={classes.avator} src={`${imageDataType},${authUser?.profilePicture}`} />}
        items={actionsList}
      /> */}
      <Tooltip title="Logout" style={{ cursor: 'pointer' }} placement="bottom-end">
        <PowerSettingsNewOutlined onClick={onItemClick} />
      </Tooltip>
      {/* <Typography className={classes.userName} display="block" variant="subtitle1">
        {authUser?.firstName} {authUser?.lastName}
      </Typography> */}
    </Box>
  );
};

export default UserDropDown;
