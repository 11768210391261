import CmtAvatar from '@coremat/CmtAvatar';
import { Box, Typography } from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import React from 'react';
import { NavLink } from 'react-router-dom';

const Logo = ({ color, ...props }) => {
  const logoUrl = color === 'white' ? '/images/erlLogo.jpg' : '/images/erlLogo.jpg';
  const logoSymbolUrl = color === 'white' ? '/images/erlLogo.jpg' : '/images/erlLogo.jpg';

  return (
    <Box {...props}>
      <Hidden xsDown>
        <Box display="flex">
          <Box mr={3} style={{ border: '2px solid white', background: 'white', borderRadious: '50%' }}>
            <NavLink to="/">
              <CmtAvatar size="small" variant="square" src={logoUrl} alt="logo" />
            </NavLink>
          </Box>
          <Box>
            <Typography variant="h3" style={{ color: 'White', marginTop: '15px', fontWeight: 'bold' }}>
              Eastern Refinery Limited
            </Typography>
          </Box>
        </Box>
      </Hidden>
      <Hidden smUp>
        <Box display="flex">
          <Box
            mr={3}
            style={{
              border: '2px solid white',
              background: 'white',
              borderRadious: '5%',
              marginLeft: '100px'
            }}>
            <NavLink to="/">
              <CmtAvatar size="small" variant="square" src={logoSymbolUrl} alt="logo" />
            </NavLink>
          </Box>
          {/* <Box>
            <Typography variant="h3" style={{ color: 'White', marginTop: '15px', fontWeight: 'bold' }}>
              Eastern Refinery Limited
            </Typography>
          </Box> */}
        </Box>
      </Hidden>
    </Box>
  );
};

export default Logo;
