import CmtHorizontal from '@coremat/CmtNavigation/Horizontal';
import { ArrowForward, ShoppingCart } from '@material-ui/icons';
import React from 'react';
import { useSelector } from 'react-redux';

const HeaderMenu = () => {
  const { authUser } = useSelector(({ auth }) => auth);
  const navigationMenus = [
    // {
    //   name: 'Main',
    //   icon: <CenterFocusWeakOutlined />,
    //   type: 'section',
    //   children: [
    //     {
    //       name: 'Test',
    //       icon: <PostAdd />,
    //       type: 'item',
    //       link: '/test'
    //     }
    //   ]
    // },

    {
      name: 'Client Request',
      icon: <ShoppingCart />,
      type: 'section',
      children: [
        {
          name: 'Request List',
          type: 'item',
          icon: <ArrowForward />,
          link: '/orderlist'
        },
        {
          name: 'Request Confirmation',
          type: 'item',
          icon: <ArrowForward />,
          link: '/confirmorderlist'
        }
      ]
    }
  ];

  return <CmtHorizontal menuItems={navigationMenus} />;
};

export default HeaderMenu;
